import React, { useState } from "react"
import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Button,
  Flex,
} from "@chakra-ui/react"
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation"
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger"
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation"
import Line from "../components/Line"
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink"
import AnimatedCountUpDecimal from "./AnimatedCountUpDecimal"
function ContentTeaser({
  columnsSpan,
  smallText,
  smallText2,
  bigText,
  colorSmall,
  colorBig,
  preis,
}) {
  const [isHovered, setHover] = useState(false)

  return (
    <GridItem colSpan={columnsSpan}>
      <InViewTrigger threshold={0.1} triggerOnce={false}>
        {inView => (
          <Box
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            // boxShadow={isHovered ? "xs" : "xs"}
            w="100%"
            borderRadius="2xl"
            p={4}
            bg={isHovered ? "brand.tan.ulight" : "brand.tan.white"}
          >
            <Line
              duration={1}
              delay={0}
              inView={inView}
              bg={"brand.gray.vlight"}
              h={"1px"}
              my={8}
            />
            <MaskSlidInAnimation threshold={1} duration={0.6}>
              <Heading
                color={colorBig}
                fontSize={{ lg: "5xl", md: "5xl", base: "5xl" }}
                fontWeight="normal"
                w={"100%"}
                letterSpacing="tighter"
              >
                {bigText}
              </Heading>
            </MaskSlidInAnimation>
            <FadeInAnimation
              duration={1}
              delay={0}
              initialY={-5}
              threshold={0.4}
            >
              <Heading
                color={"brand.gray.vlight"}
                fontSize={{ lg: "lg", md: "lg", base: "lg" }}
                fontWeight="normal"
                w={"100%"}
                letterSpacing="tighter"
              >
                {smallText}
              </Heading>
            </FadeInAnimation>
            <FadeInAnimation
              duration={1.5}
              delay={0}
              initialY={-10}
              threshold={0.4}
            >
              <Text color={"brand.gray.vlight"} fontSize="sm">
                {smallText2}
              </Text>
            </FadeInAnimation>
            <InViewTrigger threshold={0.1} triggerOnce={false}>
              {inView => (
                <Box py={8}>
                  <Flex
                    ml={"0%"}
                    w="30%"
                    justifyContent="flex-end"
                    flexDirection={{ base: "column", lg: "row" }}
                    gap={{ base: 8, lg: 0 }}
                  >
                    <Box w="100%" mr={4}>
                      <Line
                        duration={2}
                        delay={0}
                        inView={inView}
                        bg={"brand.medgray"}
                        h={"1px"}
                        my={2}
                      />
                      <AnimatedCountUpDecimal
                        inView={inView}
                        unit="€"
                        fontSizeUnit="lg"
                        count={preis}
                        fontSize={"6rem"}
                        duration={1}
                        decimal={false}
                        decimalDuration={2}
                        decimalCount={43}
                        unitSpacing={3}
                        numberColor={"brand.gray.vlight"}
                        textColor={"brand.gray.vlight"}
                        cellHeight={"6rem"}
                        letterSpacing={"-0.2rem"}
                      />
                    </Box>
                  </Flex>
                </Box>
              )}
            </InViewTrigger>
            <RouterLink isExternal={false} link={"/slider"}>
              <Button
                mt={4}
                variant="solid"
                bg="brand.gray.light"
                color="brand.white"
              >
                Mehr erfahren
              </Button>
            </RouterLink>
          </Box>
        )}
      </InViewTrigger>
    </GridItem>
  )
}

export default ContentTeaser
