import React from 'react'
import { Box } from '@chakra-ui/react'
import { motion } from 'framer-motion'
const MotionBox = motion(Box);
export default function Line({ 
  inView, 
  duration, 
  delay,
  bezierCurve,
  ...restProps  
}) {
  return (
    <MotionBox
    {...restProps}
      initial={{width: '0%'}}
      animate={{
        width: inView && '100%',
        transition: {
          duration: duration,
          delay: delay,
          ease: bezierCurve
        }
      }}
    > 
    </MotionBox>
  )
}
