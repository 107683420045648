import { Container, Grid } from "@chakra-ui/react"
import ContentTeaser from "../contentTeaser"
import React from "react"

function TwoProducts(
  preis1,
  preis2,
  bigtext1,
  bigtext2,
  smalltext1,
  smalltext2
) {
  return (
    <Container variant="layoutContainer">
      <Grid my={12} templateColumns="repeat(12, 1fr)" gridGap={4}>
        <ContentTeaser
          preis={"12"}
          columnsSpan="6"
          smallText="about us3"
          smallText2="aboutasdasd us3"
          bigText="Produktangebot 1"
          colorSmall="brand.gray.100"
          colorBig="brand.gray.vlight"
        />
        <ContentTeaser
          columnsSpan="6"
          smallText="about us3"
          bigText="Produktangebot2"
          smallText2="aboutasdasd us3"
          colorSmall="brand.gray.100"
          colorBig="brand.gray.vlight"
        />
      </Grid>
    </Container>
  )
}

export default TwoProducts
