import { Box, Container, Text, useColorMode } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BannerBlepharo from "../../components/choc/BannerBlepharo"
import BannerHyaloron from "../../components/choc/BannerHyaloron"
import BannerVeoJadeklinik from "../../components/choc/BannerVeoJadeklinik"
import LogoHeadlineTeaser from "../../components/choc/LogoHeadlineTeaser"
import LogoHeadlineTeaserVeo from "../../components/choc/LogoHeadlineTeaserVEO"
import QuadTeaser from "../../components/choc/QuadTeaser"
import TitleScrollHeader from "../../components/choc/TitleScrollHeader"
import TwoProducts from "../../components/choc/TwoProducts"
import IntersectionTrigger from "../../components/IntersectionTrigger"
import LayoutA from "../../components/layout/LayoutA"
import ScrollHeader from "../../components/ScrollHeader"
import ThreeColumnGridBoxes from "../../components/ThreeColumnGridBoxes"

const Blank = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <LayoutA
      title={"Display"}
      description={"The boundaries of motion-design are pushed in obscurity"}
    >
      <ScrollHeader />
      <LogoHeadlineTeaser
        py={24}
        headline="VeoAesthetics"
        subheadline="Wahre Schönheit kommt aus Ihnen"
      />

      <Container variant="layoutContainer" mt={12}>
        <ThreeColumnGridBoxes />
      </Container>
      <TwoProducts />
      <BannerBlepharo />
      <BannerHyaloron />
      <LogoHeadlineTeaserVeo
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      />
      <QuadTeaser />
      <Container variant="layoutContainer">
        <BannerVeoJadeklinik />
      </Container>
    </LayoutA>
  )
}

export default Blank
