import { Box } from '@chakra-ui/react'
import { motion, useViewportScroll } from 'framer-motion'
import { useEffect, useState } from 'react'
const MotionBox = motion(Box)

const IntersectionTriggerInner = ({
  threshold, 
  triggerOnce,
  SetScrollProgressKeyPointTopEdgeViewport,
  children
}) => {

  const { scrollYProgress } = useViewportScroll()
  const [scrollProgress, setScrollProgress] = useState(0)
  const [inView, setInView] = useState(0)
  const bar = SetScrollProgressKeyPointTopEdgeViewport(threshold)

  useEffect(() => scrollYProgress.onChange((v) => { 
    setScrollProgress(v)
  }), [scrollYProgress])

  useEffect(() => { 
    if(scrollProgress > bar){
      setInView(true)
    }
    if(scrollProgress < bar && !triggerOnce){
      setInView(false)
    }
  }, [scrollProgress])

  // execute first check on render
  useEffect(() => { 
    if(scrollProgress > bar){
      setInView(true)
    }
    if(scrollProgress < bar && !triggerOnce){
      setInView(false)
    }
  })


  return (children(inView))
}

export default IntersectionTriggerInner